<template>
  <div>
    <Close />
    <v-pannellum
      :src="images[roomId]"
      id="threesixty"
      @load="loaded"
      :mouseZoom="false"
    ></v-pannellum>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Vue from 'vue'
import Close from '@/components/Close'
import VuePannellum from 'vue-pannellum'

Vue.component('VPannellum', VuePannellum)

export default {
  components: {
    Close
  },
  data () {
    return {
      isLoading: true, // Shows buefy insted of pannellum loading overlay
      isFullPage: true,
      images: {
        1: `${require('@/assets/reading_room/bguc-360-room-1-hires.jpg')}`,
        2: `${require('@/assets/attached_room/bguc-360-room-2-hires.jpg')}`
      }
    }
  },
  computed: {
    roomId () {
      return this.$store.state.activeRoom
    }
  },
  methods: {
    loaded () {
      this.isLoading = false
    }
  }
}
</script>
